import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
     <footer class="footer-section">
        <div class="copyright-area">
            <div class="container">
                <div class="row">
                    <div class="footer-copy-width-1 col-xl-6 col-lg-6 text-lg-left">
                        <div class="copyright-text">
                            <p>Copyright &copy; 2024, Reserved Created By <Link to="/contact">JScoder</Link> & <Link to="/contact">FTcoder</Link></p>
                        </div>
                    </div>
                    <div class="footer-copy-width-2 col-xl-6 col-lg-6 d-lg-block text-right">
                        <div class="footer-menu">
                            <ul style={{margin: 0, display: "inline", float: "right"}}>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/tearms-and-condition">Terms</Link></li>
                                <li><Link to="/privacy-policy">Privacy & Policy</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                                <li><Link to="/page-not-found">404</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer