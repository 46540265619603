import React from 'react'
import GameBox from '../Components/GameBox'

function Play() {
  return (
    <>
    <title>Justify Games - Play Page Run Game And Have Fun With Us</title>

    <span style={{marginTop: "15px", display: "block"}}></span>
     <GameBox />
    </>
  )
}

export default Play