import React from 'react'
import { Link } from 'react-router-dom'

import Game1 from '../Assets/GameAssets/AlienConnect.jpg';
import Game2 from '../Assets/GameAssets/BaggeageReclaim.jpg';
import Game3 from '../Assets/GameAssets/BallTube.jpg';
import Game4 from '../Assets/GameAssets/BitCoinMillionaire.jpg';
import Game5 from '../Assets/GameAssets/BlockTower.jpg';
import Game6 from '../Assets/GameAssets/BrickBreaker.jpg';
import Game7 from '../Assets/GameAssets/BridgeRunner.jpg';
import Game8 from '../Assets/GameAssets/CatMatch.jpg';
import Game9 from '../Assets/GameAssets/ColorHit.jpg';
import Game10 from '../Assets/GameAssets/ColorShot.jpg';
import Game11 from '../Assets/GameAssets/DinoClicker.jpg';
import Game12 from '../Assets/GameAssets/DiscDestroy.jpg';
import Game13 from '../Assets/GameAssets/DriftBus.jpg';
import Game14 from '../Assets/GameAssets/FindThe3Diffrence.jpg';
import Game15 from '../Assets/GameAssets/FlyingJet.jpg';
import Game16 from '../Assets/GameAssets/GrassCutting.jpg';
import Game17 from '../Assets/GameAssets/HatHands.jpg';
import Game18 from '../Assets/GameAssets/NightNinja.jpg';
import Game19 from '../Assets/GameAssets/PandaBloock.jpg';
import Game20 from '../Assets/GameAssets/Pirates.jpg';
import Game21 from '../Assets/GameAssets/PrepositionQuest.jpg';
import Game22 from '../Assets/GameAssets/RLeangue.jpg';
import Game23 from '../Assets/GameAssets/RopeChallenge.jpg';
import Game24 from '../Assets/GameAssets/Snowland.jpg';
import Game25 from '../Assets/GameAssets/SummerMaze.jpg';
import Game26 from '../Assets/GameAssets/SuperBouncyEgg.jpg';
import Game27 from '../Assets/GameAssets/TheViking.jpg';
import Game28 from '../Assets/GameAssets/TowerOfFall.jpg';
import Game29 from '../Assets/GameAssets/TwoCatCute.jpg';
import Game30 from '../Assets/GameAssets/ValentineHidden.jpg';

function GameBox() {
    return (
        <>
            <div className="GameBox">
                <div className="GB-Main">
                    <div className="GB-Grid">
                        <Link to="/alien-connect" title="alien connect">
                            <div className="GB-GamePart GB-GamePartNew1">
                                <img src={Game1} alt="Justify Games alien connect" />
                                <div className="GB-Name">
                                    <p>Alien Connect</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/baggeage-reclaim" title="baggeage reclaim">
                            <div className="GB-GamePart GB-GamePartHot1">
                                <img src={Game2} alt="Justify Games baggeage reclaim" />
                                <div className="GB-Name">
                                    <p>Baggeage Reclaim</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/ball-tube" title="ball tube">
                            <div className="GB-GamePart GB-GamePartNew2">
                                <img src={Game3} alt="Justify Games ball tube" />
                                <div className="GB-Name">
                                    <p>Ball Tube</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/bitcoin-millionaire" title="bitcoin millionaire">
                            <div className="GB-GamePart GB-GamePartHot2">
                                <img src={Game4} alt="Justify Games bitcoin millionaire" />
                                <div className="GB-Name">
                                    <p>BitCoin Millionaire</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/block-tower" title="block tower">
                            <div className="GB-GamePart">
                                <img src={Game5} alt="Justify Games block tower" />
                                <div className="GB-Name">
                                    <p>Block Tower</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/brick-breaker" title="brick breaker">
                            <div className="GB-GamePart">
                                <img src={Game6} alt="Justify Games brick breaker" />
                                <div className="GB-Name">
                                    <p>Brick Breaker</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/bridge-runner" title="bridge runner">
                            <div className="GB-GamePart">
                                <img src={Game7} alt="Justify Games bridge runner" />
                                <div className="GB-Name">
                                    <p>Bridge Runner</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/cat-match" title="cat match">
                            <div className="GB-GamePart">
                                <img src={Game8} alt="Justify Games cat match" />
                                <div className="GB-Name">
                                    <p>Cat Match</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/color-hit" title="color hit">
                            <div className="GB-GamePart">
                                <img src={Game9} alt="Justify Games color hit" />
                                <div className="GB-Name">
                                    <p>Color Hit</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/color-shot" title="color shot">
                            <div className="GB-GamePart">
                                <img src={Game10} alt="Justify Games color shot" />
                                <div className="GB-Name">
                                    <p>Color Shot</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/dino-clicker" title="dino clicker">
                            <div className="GB-GamePart">
                                <img src={Game11} alt="Justify Games dino clicker" />
                                <div className="GB-Name">
                                    <p>Dino Clicker</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/disc-destroy" title="disc destroy">
                            <div className="GB-GamePart">
                                <img src={Game12} alt="Justify Games disc destroy" />
                                <div className="GB-Name">
                                    <p>Disc Destroy</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/drift-bus" title="drift bus">
                            <div className="GB-GamePart">
                                <img src={Game13} alt="Justify Games drift bus" />
                                <div className="GB-Name">
                                    <p>Drift Bus</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/find-diffrence" title="find diffrence">
                            <div className="GB-GamePart">
                                <img src={Game14} alt="Justify Games find diffrence" />
                                <div className="GB-Name">
                                    <p>Find Diffrence</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/flying-jet" title="flying jet">
                            <div className="GB-GamePart">
                                <img src={Game15} alt="Justify Games flying jet" />
                                <div className="GB-Name">
                                    <p>Flying Jet</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/grass-cutting" title="grass cutting">
                            <div className="GB-GamePart">
                                <img src={Game16} alt="Justify Games grass cutting" />
                                <div className="GB-Name">
                                    <p>Grass Cutting</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/hat-hands" title="hat hands">
                            <div className="GB-GamePart">
                                <img src={Game17} alt="Justify Games hat hands" />
                                <div className="GB-Name">
                                    <p>Hat Hands</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/night-ninja" title="night ninja">
                            <div className="GB-GamePart">
                                <img src={Game18} alt="Justify Games night ninja" />
                                <div className="GB-Name">
                                    <p>Night Ninja</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/panda-bloock" title="panda bloock">
                            <div className="GB-GamePart">
                                <img src={Game19} alt="Justify Games panda bloock" />
                                <div className="GB-Name">
                                    <p>Panda Bloock</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/pirates" title="pirates">
                            <div className="GB-GamePart">
                                <img src={Game20} alt="Justify Games pirates" />
                                <div className="GB-Name">
                                    <p>Pirates</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/preposition-quest" title="preposition quest">
                            <div className="GB-GamePart">
                                <img src={Game21} alt="Justify Games preposition quest" />
                                <div className="GB-Name">
                                    <p>Preposition Quest</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/rleangue" title="rleangue">
                            <div className="GB-GamePart">
                                <img src={Game22} alt="Justify Games rleangue" />
                                <div className="GB-Name">
                                    <p>RLeangue</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/rope-challenge" title="rope challenge">
                            <div className="GB-GamePart">
                                <img src={Game23} alt="Justify Games rope challenge" />
                                <div className="GB-Name">
                                    <p>Rope Challenge</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/snowland" title="snowland">
                            <div className="GB-GamePart">
                                <img src={Game24} alt="Justify Games snowland" />
                                <div className="GB-Name">
                                    <p>Snowland</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/summer-maze" title="summer maze">
                            <div className="GB-GamePart">
                                <img src={Game25} alt="Justify Games summer maze" />
                                <div className="GB-Name">
                                    <p>Summer Maze</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/super-bouncy-egg" title="super bouncy egg">
                            <div className="GB-GamePart">
                                <img src={Game26} alt="Justify Games super bouncy egg" />
                                <div className="GB-Name">
                                    <p>Super Bouncy Egg</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/the-viking" title="the viking">
                            <div className="GB-GamePart">
                                <img src={Game27} alt="Justify Games the viking" />
                                <div className="GB-Name">
                                    <p>The Viking</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/tower-if-fall" title="tower if fall">
                            <div className="GB-GamePart">
                                <img src={Game28} alt="Justify Games tower if fall" />
                                <div className="GB-Name">
                                    <p>Tower Of Fall</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/two-cat-cute" className='Res_Dn' title="two cat cute">
                            <div className="GB-GamePart">
                                <img src={Game29} alt="Justify Games two cat cute" />
                                <div className="GB-Name">
                                    <p>Two Cat Cute</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/valentine-hidden" className='Res_Dn' title="valentine hidden">
                            <div className="GB-GamePart">
                                <img src={Game30} alt="Justify Games valentine hidden" />
                                <div className="GB-Name">
                                    <p>Valentine Hidden</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GameBox