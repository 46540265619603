import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../Assets/Logo-Name.png'

function Page404() {
    return (
        <>
            <title>Justify Games - Look Like You're Lost The Page You Are Looking For Not Avaible!</title>

            <section class="page_404">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 ">
                            <div class="col-sm-offset-1 respons_center text-center">
                                <div class="four_zero_four_bg">
                                    <h1 class="text-center ">404</h1>


                                </div>

                                <div class="contant_box_404">
                                    <div>
                                        <img src={logo} alt="" />
                                    </div>
                                    <h3 class="h2">
                                        Look like you're lost
                                    </h3>

                                    <p>the page you are looking for not avaible!</p>

                                    <Link to="/" class="link_404">Go to Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Page404