import React from 'react'

function Googleads() {
    return <>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1751191908546723"
            crossorigin="anonymous"></script>
        <ins class="adsbygoogle"
            style={{display: "block"}}
            data-ad-client="ca-pub-1751191908546723"
            data-ad-slot="9387653123"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({ });
        </script>
    </>
}

export default Googleads