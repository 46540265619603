import React from 'react'
import { Link } from 'react-router-dom'

import Game1 from '../Assets/GameAssets/AlienConnect.jpg';
import Game2 from '../Assets/GameAssets/BaggeageReclaim.jpg';
import Game3 from '../Assets/GameAssets/BallTube.jpg';
import Game4 from '../Assets/GameAssets/BitCoinMillionaire.jpg';
import Game5 from '../Assets/GameAssets/BlockTower.jpg';
import Game6 from '../Assets/GameAssets/BrickBreaker.jpg';
import Game7 from '../Assets/GameAssets/BridgeRunner.jpg';
import Game8 from '../Assets/GameAssets/CatMatch.jpg';
import Game9 from '../Assets/GameAssets/ColorHit.jpg';
import Game10 from '../Assets/GameAssets/ColorShot.jpg';
import Game11 from '../Assets/GameAssets/DinoClicker.jpg';
import Game12 from '../Assets/GameAssets/DiscDestroy.jpg';
import Game13 from '../Assets/GameAssets/DriftBus.jpg';
import Game14 from '../Assets/GameAssets/FindThe3Diffrence.jpg';
import Game15 from '../Assets/GameAssets/FlyingJet.jpg';
import Game16 from '../Assets/GameAssets/GrassCutting.jpg';
import Game17 from '../Assets/GameAssets/HatHands.jpg';
import Game18 from '../Assets/GameAssets/NightNinja.jpg';
import Game19 from '../Assets/GameAssets/PandaBloock.jpg';
import Game20 from '../Assets/GameAssets/Pirates.jpg';
import Game21 from '../Assets/GameAssets/PrepositionQuest.jpg';
import Game22 from '../Assets/GameAssets/RLeangue.jpg';
import Game23 from '../Assets/GameAssets/RopeChallenge.jpg';
import Game24 from '../Assets/GameAssets/Snowland.jpg';
import Game25 from '../Assets/GameAssets/SummerMaze.jpg';
import Game26 from '../Assets/GameAssets/SuperBouncyEgg.jpg';
import Game27 from '../Assets/GameAssets/TheViking.jpg';
import Game28 from '../Assets/GameAssets/TowerOfFall.jpg';
import Game29 from '../Assets/GameAssets/TwoCatCute.jpg';
import Game30 from '../Assets/GameAssets/ValentineHidden.jpg';

function Blog() {
  return (
    <>
      <title>Justify Games - Blog Page All Types Off Games</title>

      <div className="wrapper">
        <div className="Main_Blog">
          <div className="Sec_Blog">
            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game1} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Alien Connect</h3>
                  <p>Play <b>Alien Connect</b> Game Ane Connect All The Aliens And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/alien-connect">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game2} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Baggeage Reclaim</h3>
                  <p>Play <b>Baggeage Reclaim</b> Game Ane Connect All The Baggeage And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/baggeage-reclaim">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game3} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Ball Tube</h3>
                  <p>Play <b>Ball Tube</b> Game Ane Connect All The Ball And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/ball-tube">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game4} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>BitCoin Millionaire</h3>
                  <p>Play <b>BitCoin Millionaire</b> Game Ane Connect All The BitCoin And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/bitcoin-millionaire">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game5} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Block Tower</h3>
                  <p>Play <b>Block Tower</b> Game Ane Connect All The Block And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/block-tower">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game6} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Brick Breaker</h3>
                  <p>Play <b>Brick Breaker</b> Game Ane Connect All The Brick And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/brick-breaker">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game7} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Bridge Runner</h3>
                  <p>Play <b>Bridge Runner</b> Game Ane Connect All The Bridge And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/bridge-runner">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game8} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Cat Match</h3>
                  <p>Play <b>Cat Match</b> Game Ane Connect All The Cat And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/cat-match">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game9} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Color Hit</h3>
                  <p>Play <b>Color Hit</b> Game Ane Connect All The Color And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/color-hit">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game10} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Color Shot</h3>
                  <p>Play <b>Color Shot</b> Game Ane Connect All The Color And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/color-shot">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game11} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Dino Clicker</h3>
                  <p>Play <b>Dino Clicker</b> Game Ane Connect All The Dino And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/dino-clicker">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game12} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Disc Destroy</h3>
                  <p>Play <b>Disc Destroy</b> Game Ane Connect All The Disc And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/disc-destroy">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game13} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Drift Bus</h3>
                  <p>Play <b>Drift Bus</b> Game Ane Connect All The Drift And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/drift-bus">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game14} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Find Diffrence</h3>
                  <p>Play <b>Find The 3 Diffrence</b> Game Ane Connect All The Find And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/find-diffrence">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game15} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Flying Jet</h3>
                  <p>Play <b>Flying Jet</b> Game Ane Connect All The Flying And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/flying-jet">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game16} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Grass Cutting</h3>
                  <p>Play <b>Grass Cutting</b> Game Ane Connect All The Grass And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/grass-cutting">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game17} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Hat Hands</h3>
                  <p>Play <b>Hat Hands</b> Game Ane Connect All The Hat And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/hat-hands">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game18} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Night Ninja</h3>
                  <p>Play <b>Night Ninja</b> Game Ane Connect All The Night And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/night-ninja">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game19} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Panda Bloock</h3>
                  <p>Play <b>Panda Bloock</b> Game Ane Connect All The Panda And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/panda-bloock">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game20} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Pirates</h3>
                  <p>Play <b>Pirates</b> Game Ane Connect All The Pirates And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/pirates">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game21} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Preposition Quest</h3>
                  <p>Play <b>Preposition Quest</b> Game Ane Connect All The Preposition And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/preposition-quest">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game22} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>RLeangue</h3>
                  <p>Play <b>RLeangue</b> Game Ane Connect All The RLeangue And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/rleangue">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game23} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Rope Challenge</h3>
                  <p>Play <b>Rope Challenge</b> Game Ane Connect All The Rope And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/rope-challenge">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game24} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Snowland</h3>
                  <p>Play <b>Snowland</b> Game Ane Connect All The Snowland And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/snowland">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game25} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Summer Maze</h3>
                  <p>Play <b>Summer Maze</b> Game Ane Connect All The Summer And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/summer-maze">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game26} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Super Bouncy Egg</h3>
                  <p>Play <b>Super Bouncy Egg</b> Game Ane Connect All The Super And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/super-bouncy-egg">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game27} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>The Viking</h3>
                  <p>Play <b>The Viking</b> Game Ane Connect All The Viking And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/the-viking">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game28} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Tower Of Fall</h3>
                  <p>Play <b>Tower Of Fall</b> Game Ane Connect All The Tower And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/tower-if-fall">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game29} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Two Cat Cute</h3>
                  <p>Play <b>Two Cat Cute</b> Game Ane Connect All The Two And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/two-cat-cute">Play Now</Link>
                </div>
              </div>
            </div>

            <div className="SB-Blog">
              <div className="SB-Blog_Image">
                <img src={Game30} alt="Game Icon Not Load" />
              </div>

              <div className="SB-Blog_Description">
                <div>
                  <h3 style={{ color: "#8C52FE", fontWeight: "bold" }}>Valentine Hidden</h3>
                  <p>Play <b>Valentine Hidden</b> Game Ane Connect All The Valentine And Win The Match When You Play The Game You Can Handle With Some Key Like a Mouse Butttons "W Key", "S Key", "A Key", "D Key", "Up Arrow", "Down Arrow", "Left Arrow", "Right Arrow", "Enter Key", "Space Key" And More keys In Some Other Games.</p>

                  <Link className='Play_Btn' to="/valentine-hidden">Play Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog