import React from 'react'
import GameBox from '../../Components/GameBox'

function G1() {
  return (
    <>
    <title>Justify Games - Alien Connect Game</title>

      <div className="Main_Gaming_Box">
        <div className="MGB-Frame">
        <iframe src="https://justify-games-jscoder.netlify.app/alienconnect" frameborder="0" title='Game Box'></iframe>
        </div>

        <GameBox />
      </div>
    </>
  )
}

export default G1