import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";

import Home from "./Views/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Play from "./Views/Play";
import Contact from "./Views/Contact";
import Terms from "./Views/Terms";
import Privacypolicy from "./Views/Privacypolicy";
import Blog from "./Views/Blog";

import G1 from "./Views/Games/G1";
import G2 from "./Views/Games/G2";
import G3 from "./Views/Games/G3";
import G4 from "./Views/Games/G4";
import G5 from "./Views/Games/G5";
import G6 from "./Views/Games/G6";
import G7 from "./Views/Games/G7";
import G8 from "./Views/Games/G8";
import G9 from "./Views/Games/G9";
import G10 from "./Views/Games/G10";

import G11 from "./Views/Games/G11";
import G12 from "./Views/Games/G12";
import G13 from "./Views/Games/G13";
import G14 from "./Views/Games/G14";
import G15 from "./Views/Games/G15";
import G16 from "./Views/Games/G16";
import G17 from "./Views/Games/G17";
import G18 from "./Views/Games/G18";
import G19 from "./Views/Games/G19";
import G20 from "./Views/Games/G20";

import G21 from "./Views/Games/G21";
import G22 from "./Views/Games/G22";
import G23 from "./Views/Games/G23";
import G24 from "./Views/Games/G24";
import G25 from "./Views/Games/G25";
import G26 from "./Views/Games/G26";
import G27 from "./Views/Games/G27";
import G28 from "./Views/Games/G28";
import G29 from "./Views/Games/G29";
import G30 from "./Views/Games/G30";
import Page404 from "./Components/Page404";
import Seo from "./Components/Seo/Seo";
import Googleads from "./Components/Ads/Googleads";
// import Preloader from "./Components/Preloader";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  return (
    <>
    {/* <Preloader /> */}
      <Navbar />
      <Seo />
      <Routes>
        
        <Route exact path="/" element={<Home />} />
        <Route exact path="/page-not-found" element={<Page404 />} />
        <Route path="/*" element={<Page404 />} />
        <Route exact path="/play" element={<Play />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/tearms-and-condition" element={<Terms />} />
        <Route exact path="/privacy-policy" element={<Privacypolicy />} />
        
        <Route exact path="/alien-connect" element={<G1 />} />
        <Route exact path="/baggeage-reclaim" element={<G2 />} />
        <Route exact path="/ball-tube" element={<G3 />} />
        <Route exact path="/bitcoin-millionaire" element={<G4 />} />
        <Route exact path="/block-tower" element={<G5 />} />
        <Route exact path="/brick-breaker" element={<G6 />} />
        <Route exact path="/bridge-runner" element={<G7 />} />
        <Route exact path="/cat-match" element={<G8 />} />
        <Route exact path="/color-hit" element={<G9 />} />
        <Route exact path="/color-shot" element={<G10 />} />

        <Route exact path="/dino-clicker" element={<G11 />} />
        <Route exact path="/disc-destroy" element={<G12 />} />
        <Route exact path="/drift-bus" element={<G13 />} />
        <Route exact path="/find-diffrence" element={<G14 />} />
        <Route exact path="/flying-jet" element={<G15 />} />
        <Route exact path="/grass-cutting" element={<G16 />} />
        <Route exact path="/hat-hands" element={<G17 />} />
        <Route exact path="/night-ninja" element={<G18 />} />
        <Route exact path="/panda-bloock" element={<G19 />} />
        <Route exact path="/pirates" element={<G20 />} />

        <Route exact path="/preposition-quest" element={<G21 />} />
        <Route exact path="/rleangue" element={<G22 />} />
        <Route exact path="/rope-challenge" element={<G23 />} />
        <Route exact path="/snowland" element={<G24 />} />
        <Route exact path="/summer-maze" element={<G25 />} />
        <Route exact path="/super-bouncy-egg" element={<G26 />} />
        <Route exact path="/the-viking" element={<G27 />} />
        <Route exact path="/tower-if-fall" element={<G28 />} />
        <Route exact path="/two-cat-cute" element={<G29 />} />
        <Route exact path="/valentine-hidden" element={<G30 />} />
      </Routes>
      <Googleads />
      <Footer />
    </>
  );
}

export default App;
