import React from 'react'
import GameBox from '../../Components/GameBox'

function G15() {
  return (
    <>
    <title>Justify Games - Flying Jet Game</title>

      <div className="Main_Gaming_Box">
        <div className="MGB-Frame">
        <iframe src="https://justify-games-jscoder.netlify.app/flyingjet" frameborder="0" title='Game Box'></iframe>
        </div>

        <GameBox />
      </div>
    </>
  )
}

export default G15