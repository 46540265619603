import React from 'react'

function Seo() {
    return (
        <>
            <head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="description" content="Web site created using create-react-app" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1751191908546723" crossorigin="anonymous"></script>
                <meta name="google-adsense-account" content="ca-pub-1751191908546723" />
                <meta name="description" content="Play free online games at Justify Games! Explore a wide variety of puzzle, action, and multiplayer games. Join now for endless gaming fun!" />
                <meta property="og:site_name" content="justifygames.fun" />
                <meta property="og:title" content="Justify Games" />
                <meta property="og:url" content="https://justifygames.fun/" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Justify Games is a free io Multiplayer Kart Battle Arena game. Drive fast. Fire rockets. Make big explosions." />
                <meta property="og:image" content="images/icon-144.png" />
                <meta property="og:image:width" content="144" />
                <meta property="og:image:height" content="144" />
                <meta itemprop="name" content="Justify Games" />
                <meta itemprop="url" content="https://justifygames.fun/" />
                <meta itemprop="description" content="Justify Games is a free io Multiplayer Kart Battle Arena game. Drive fast. Fire rockets. Make big explosions." />
                <meta itemprop="thumbnailUrl" content="https://justifygames.fun/" />
                <link rel="image_src" href="https://justifygames.fun/" />
                <meta itemprop="image" content="https://justifygames.fun/" />
                <meta name="twitter:title" content="Justify Games" />
                <meta name="twitter:image" content="images/icon-144.png" />
                <meta name="twitter:url" content="https://justifygames.fun/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content="Justify Games is a free io Multiplayer Kart Battle Arena game. Drive fast. Fire rockets. Make big explosions." />
                <meta name="description" content="Justify Games is a free io Multiplayer Kart Battle Arena game. Drive fast. Fire rockets. Make big explosions." />
                <meta itemprop="image primaryImageOfPage" content="images/justifygames.png" />
                <link rel="manifest" href="manifest.json" />
                <meta name="apple-mobile-web-app-title" content="Justify Games" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <link rel="apple-touch-icon" href="images/icon-144.png" />
            </head>
        </>
    )
}

export default Seo;